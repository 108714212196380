/**
 * 概念术语相关方法封装处理
 *  主要是对概念字典进行初始化,缓存入 store，以及从缓存中获取概念字典等
 *  2021-04-22  wuzhipeng
 */

import { getConceptsByDomainCode, getFormConceptList, getAtcAndCpdConceptList } from '@/api/term/concept'
// getPhaClassConceptList
import store from '@/store/index'
import { setCache, getCache } from '@/utils/cacheUtil'
import conceptConst from '@/const/conceptConst'
/*import { handleTree } from '@/utils/common'*/

const {} = conceptConst

export function setConcept(domainCode, includeSon, data) {
  store.dispatch('concept/addConcept', { key: domainCode, value: data, includeSon: includeSon })
  //console.log("qq",store.state.concept.concepts,store.state.concept.title)
  //console.log('LANGUAGE_DOMAIN_CODE',LANGUAGE_DOMAIN_CODE)
}

// 根据概念域代码获取缓存的概念数据
export async function getConcept(domainCode, includeSon) {
  if (includeSon == undefined) {
    includeSon = 0
  }
  let realKey = domainCode + '#####' + includeSon
  let concepts = store.state.concept.concepts

  if (concepts[realKey] == undefined) {
    //查询概念并加入缓存
    // console.log("调用queryConcept"+domainCode)
    await queryConcept(domainCode, includeSon)
  }
  //console.log("getConcept函数返回"+domainCode,concepts[realKey])
  return concepts[realKey]

}

// 根据概念域代码调用接口查询概念数据
export async function queryConcept(domainCode, includeSon) {
  //console.log("执行查询"+domainCode)
  await getConceptsByDomainCode(domainCode, includeSon).then(response => {
    setConcept(domainCode, includeSon, response.data)
    //console.log("查询完毕--缓存值"+domainCode)
  })
  // console.log("queryConcept函数结束"+domainCode)
}

// 获取剂型概念字典树形结构
export async function getFormConceptTree() {
  let cacheKey = 'concept_form_tree'
  let treeData = getCache(cacheKey)
  if (!treeData) {
    await getFormConceptList().then(response => {
      treeData = handleFormTree(response.data, 'conceptId', 'pConceptId')
      setCache('concept_form_tree', treeData)
    })
  }
  return treeData
}
// 获取药理分类概念字典树形结构
// export async function getPhaClassConceptTree() {
//   let cacheKey = 'concept_drug_tree'
//   let treeData = getCache(cacheKey)
//   if (!treeData) {
//     await getPhaClassConceptList().then(response => {
//       treeData = handleDrugTree(response.data, 'conceptId', 'pConceptId')
//       setCache('concept_drug_tree', treeData)
//     })
//   }
//   console.log(treeData)
//   return treeData
// }
// 获取药品分类概念字典树形结构
export async function getAtcAndCpdConceptTree() {
  let cacheKey = 'concept_drug_tree'
  let treeData = getCache(cacheKey)
  if (!treeData) {
    await getAtcAndCpdConceptList().then(response => {
      treeData = handleDrugTree(response.data, 'conceptId', 'pConceptId')
      setCache('concept_drug_tree', treeData)
    })
  }
  console.log(treeData)
  return treeData
}
/*list 转换为树形结构*/
function handleFormTree(data){
  let id='conceptId'
  let parentId='pconceptId'
  let  rootId= 0
  //对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data))
  //循环所有项
  const treeData = cloneData.filter(father => {
    let branchArr = cloneData.filter(child => {
      //返回每一项的子级数组
      return father[id] === child[parentId]
    });
    branchArr.length > 0 ? father.children = branchArr : '';
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData != '' ? treeData : data;
}
/*list 转换为树形结构*/
function handleDrugTree(data){
  let id='conceptId'
  let parentId='pconceptId'
  let  rootId= -1
  //对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data))
  //循环所有项
  const treeData = cloneData.filter(father => {
    let branchArr = cloneData.filter(child => {
      //返回每一项的子级数组
      return father[id] === child[parentId]
    });
    branchArr.length > 0 ? father.children = branchArr : '';
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData != '' ? treeData : data;
}
