import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'

// 根据概念域代码查询概念信息
export function getConceptsByDomainCode(domainCode,includeSon) {
  return request({
    url: '/term/concept/getConceptsByDomainCode',
    method: 'get',
    params: {domainCode:domainCode,includeSon:includeSon}
  })
}

// 获取全部剂型，包含上级剂型（基础剂型id）
export function getFormConceptList() {
  return request({
    url: '/term/concept/getFormConceptList',
    method: 'get',
    params: {}
  })
}
// 获取全部药理分类
export function getPhaClassConceptList() {
  return request({
    url: '/term/concept/getPhaClassConceptList',
    method: 'get',
    params: {}
  })
}

// 获取全部药理分类（药品分类)
export function getAtcAndCpdConceptList() {
  return request({
    url: '/term/concept/getAtcAndCpdConceptList',
    method: 'get',
    params: {}
  })
}
